import { NavLink } from 'react-router-dom';
import { SecretContext, SecretDecryptor, promptUnlock } from '../Security';
import { useContext, useRef } from 'react';
import { imgdata } from '../data/imgdata';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

function ProtectedEmail() {
    const s = 'ZVhWaGJtaDFZbVZ5ZEVCbmJXRnBiQzVqYjIwPQ==';
    return <span className='email-button pseudolink' onClick={
        () => {
            window.location = (`mailto:${atob(atob(s))}`) as any;
        }
    } onMouseOver={
        () => {
            const ele = document.querySelector(".email-button");
            if (ele) {
                ele.innerHTML = atob(atob(s));
            }
        }
    }>{"[mouse over]"}</span>
}

function AuthNotice({ set }: { set: SecretDecryptor }) {
    return <><p>{"⚠️Site content is limited because you aren't authenticated.⚠️"}<br />
        Authenticate by entering <span className='pseudolink' onClick={promptUnlock(set)}>the passphrase</span> or by entering the site through an authenticated link. Or send me an email.</p>
    </>
}
export function Home() {
    const [secret, setSecret] = useContext(SecretContext);
    const container = useRef(null as null | HTMLDivElement);
    return <>
        <p>Hello world! 👋</p>
        <p>Welcome to my website!</p>
        {/* 
        {!secret && <AuthNotice set={setSecret} />} */}
        <div ref={container} className="imgscrollmenu" onWheel={(e) => {
            if (container.current) {
                e.preventDefault();
                container.current.scrollLeft += e.deltaY / 3;
            }
        }}>
            {imgdata.map((x, i) => <span id="imgitem" key={i}>
                <Zoom>
                    <img alt={x.alt} src={x.src} loading="lazy" />
                </Zoom>
                {x.caption}
            </span>)}
        </div>
        <p>Check out my stuff:</p>
        {/* <div className='main-links large'>
            
        </div>
        <br /> */}
        <div className='main-links center'>
            <ul>
                <li><NavLink to="/project">projects</NavLink></li>
                <li><a href="/static/files/thesis_en.pdf" rel="nofollow noreferrer">thesis (pdf)</a></li>
                <li><a href="/static/files/resume.pdf" rel="nofollow noreferrer">resume (pdf)</a></li>
            </ul>
        </div>

        <p>or email me at <ProtectedEmail /></p>
    </>;
}
