export const imgdata = [
    {
        src: "static/files/gradphoto.jpg",
        alt: "graduation at tsinghua",
        caption: <div>My graduation from the <a href="https://iiis.tsinghua.edu.cn/en/yaoclass/">Yao Class</a> at Tsinghua University</div>
    },
    // {
    //     src: "static/files/summit.jpg",
    //     alt: "mount fuji summit",
    //     caption: <div>Mt. Fuji, summit (terrible!)</div>
    // },
    {
        src: "static/files/subsummit.jpg",
        alt: "mount fuji below summit",
        caption: <div>Ascent to the summit of Mt. Fuji</div>
    },
    {
        src: "static/files/5.jpg",
        alt: "me and my sister elysia",
        caption: <div>My sister's graduation from high school <a href="https://www.linkedin.com/in/yelysia" rel="nofollow">(linkedin)</a></div>
    },
] as {
    src: string;
    alt: string;
    caption: JSX.Element;
}[];
