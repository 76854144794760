export const THESIS_DATA = {
    name: "thesis: auto-LLM-prompting",
    school: true,
    links: {
        "paper (en)": "/static/files/thesis_en.pdf",
        "paper (cn)": "/static/files/thesis_cn.pdf",
        "paper (acl)": "/static/files/thesis_acl.pdf",
        "source": "https://github.com/HubertY/auto-fewshot-research"
    },
    tech: "Python (Pytorch), Bash",
    description: "My undergraduate thesis research project, about automated few-shot prompting in large language models for code generation.",
    descriptionlong: [`Abstract:\nFew-shot prompts are difficult for humans to construct, but can be critical for the performance of large language models on downstream tasks. I propose a framework of automatically generating few-shot prompts by selecting high-quality outputs sampled from the model itself. I apply it to code generation. In testing the framework, I use High Probability Branching, a novel tree-based systematic search, demonstrated to outperform conventional sampling in accuracy and efficiency. I evaluate the performance of the framework by applying it to the GPT-J model with a subset of the HumanEval dataset. The prompt generated by the framework achieves a ten percent improvement over model performance with no prompt; the improvement is six times the improvement from the human prompt.`]
};